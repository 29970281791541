.hidden{
  display: none !important;
}

.com_t4pagebuilder .t4b-edit-btn {
  animation-name: jubBounceIn;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 1s;
  z-index: 999999;
}

.com_t4pagebuilder .t4b-edit-btn {
  display: block;
  background-color: #F44336;
  color: rgba(255,255,255,.87);
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  text-align: left;
  position: fixed;
  top: 16px;
  right: 30px;
  box-sizing: border-box;
  padding: 8px 16px 8px 8px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  border-radius: 50px;
  z-index: 100000;
  transition: .28s cubic-bezier(.4,0,.2,1);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12), 0 3px 3px -2px rgba(0, 0, 0, .4);
}

.com_t4pagebuilder .t4b-edit-btn span {
  color: #FFF;
}

.com_t4pagebuilder .t4b-edit-btn i {
  color: #fff;
	padding: 0 8px;
	margin-right: 5px;
}

.com_t4pagebuilder .t4b-edit-btn:hover,
.com_t4pagebuilder .t4b-edit-btn:active,
.com_t4pagebuilder .t4b-edit-btn:focus {
  background: #E53935;
	color: #fff;
	text-decoration: none !important;
	cursor: pointer;
	opacity: 1;
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .4);
}

.t4-edit-layout .t4b-edit-btn{
  display: none;
}
.hide{
  display:none !important;
}
.modal-open .hide{
  display:block !important;
}